@import 'styles/includes.scss';

.NavigationMobile {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    position: relative;
    width: 100%;

    &--Open {
        position: fixed;
        height: 100%;
        top: 0;
        right: 0;
        z-index: 200;
    }

    &__Container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 64px;
        border-bottom: 1px solid $colorGrey300;
        padding: 0 16px;
    }

    &__Logotype {
        background-image: url('#{$basepath}svg/logotype-visitsweden.svg');
        background-size: contain;
        background-repeat: no-repeat;
        width: 159px;
        height: 28px;
    }

    &__Actions {
        height: 100%;
        display: flex;
        align-items: center;
    }

    &__Search {
        display: inline-block;
        background-image: url('#{$basepath}svg/icon-search.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        height: 100%;
        width: 24px;
        margin-right: 16px;
    }

    &__Favorite {
        display: inline-block;
        background-image: url('#{$basepath}svg/icon-favorite.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        height: 100%;
        width: 28px;
        margin-right: 16px;
        position: relative;

        &--Filled {
            background-image: url('#{$basepath}svg/icon-favorite-filled.svg');
        }
        &[data-number]:not([data-number='0']) {
            &:after {
                content: attr(data-number);
                position: absolute;
                background: $colorYellow;
                top: 22px;
                right: -2px;
                border-radius: 50%;
                font-size: 1.2rem;
                color: $colorBlack70;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 16px;
                height: 16px;
            }
        }

        &--Active {
            border-bottom: 4px solid $colorYellow;
        }
    }

    &__Toggle {
        display: inline-block;
        background-image: url('#{$basepath}svg/icon-hamburger.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        height: 100%;
        width: 28px;

        .NavigationMobile--Open & {
            background-image: url('#{$basepath}svg/icon-close.svg');
        }
    }

    &__ItemsContainer {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: calc(100% - 64px);
        overflow: auto;
    }

    &__Items {
        display: none;
        flex-direction: column;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        background-color: #fff;

        .NavigationMobile--Open & {
            display: flex;
        }
    }

    &__ItemContainer {
        width: 100%;
        padding: 8px 16px;
        height: 56px;
        border-bottom: 1px solid $colorGrey300;
        display: flex;
        align-items: center;
        text-decoration: none;
        position: relative;

        &--SubLevel {
            background-color: $colorGrey100;
        }
    }

    &__ItemLink {
        position: absolute;
        left: 0;
        width: 100%;
        height: 90%;
    }

    &__ButtonToggle {
        width: 40px;
        height: 40px;
    }

    &__ActiveLanguage {
        font-weight: normal;
        margin-right: auto;
        margin-left: 8px;
    }

    &__Item {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        align-items: center;
        position: relative;

        &--TopLevel {
            @extend %navigation-titles;
            color: $colorBlack70;
            text-decoration: none;
        }

        &--SubLevel {
            @extend %navigation-sub-titles;
            color: $colorBlack70;
            margin-left: 8px;
        }

        &--Language {
            justify-content: flex-start;
        }

        &--Explore {
            position: relative;
            padding-left: 32px;

            &:before {
                content: '';
                display: block;
                position: absolute;
                top: 50%;
                left: 8px;
                transform: translateY(-50%);
                background-image: url('#{$basepath}svg/icon-explore.svg');
                background-size: contain;
                background-repeat: no-repeat;
                height: 16px;
                width: 16px;
            }
        }

        &--Destination,
        &--City {
            position: relative;
            padding-left: 40px;

            &:before {
                content: '';
                display: block;
                position: absolute;
                top: 50%;
                left: 24px;
                transform: translateY(-50%);
                background-image: url('#{$basepath}svg/icon-map-marker.svg');
                background-size: contain;
                background-repeat: no-repeat;
                height: 16px;
                width: 16px;
            }
        }

        &--Editorial {
            position: relative;
            padding-left: 40px;

            &:before {
                content: '';
                display: block;
                position: absolute;
                top: 50%;
                left: 12px;
                transform: translateY(-50%);
                background-image: url('#{$basepath}svg/icon-circle-arrow.svg');
                background-size: contain;
                background-repeat: no-repeat;
                height: 16px;
                width: 16px;
            }
        }

        &--Region {
            position: relative;
            padding-left: 40px;

            &:before {
                content: '';
                display: block;
                position: absolute;
                top: 50%;
                left: 24px;
                transform: translateY(-50%);
                background-image: url('#{$basepath}svg/icon-region.svg');
                background-size: contain;
                background-repeat: no-repeat;
                height: 16px;
                width: 16px;
            }
        }
    }

    &__Checkbox {
        border: 2px solid rgba($colorBlack, 0.7);
        display: block;
        height: 16px;
        width: 16px;
        position: relative;
        margin-right: 8px;
        background-color: #fff;

        &--Active {
            &:before {
                content: '';
                background-color: $colorYellow;
                position: absolute;
                left: 2px;
                top: 50%;
                transform: translateY(-50%);
                display: block;
                height: 8px;
                width: 8px;
            }
        }
    }
}
