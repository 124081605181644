@import 'styles/includes.scss';

.NavigationSearch {
    @include media(m) {
        left: initial;
    }
    width: 100%;
    position: absolute;
    left: 8px;

    &__Wrap {
        position: relative;
        display: flex;
        width: 100%;

        &--ShowsSuggestions {
            z-index: $z-above-dimmed;
        }
    }

    &__Button {
        border: none;
        background-color: $colorYellow;
        width: 50px;
        background-image: url('#{$basepath}svg/icon-search.svg');
        background-repeat: no-repeat;
        background-position: 54% 55%;
        background-size: 50%;
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
        cursor: pointer;
        flex-shrink: 0;
    }

    &__Cancel {
        border: none;
        background-color: #fff;
        width: 50px;
        background-image: url('#{$basepath}svg/icon-close.svg');
        background-repeat: no-repeat;
        background-position: 54% 55%;
        background-size: 50%;
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
        cursor: pointer;
        flex-shrink: 0;
        margin-right: 10px;

        @include media(m) {
            margin-right: 0;
        }
    }

    &__Input {
        color: rgba(0, 0, 0, 0.7);
        font-size: 1.4rem;
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
        border: none;
        padding: 16px;
        background-color: $colorGrey100;
        width: 100%;
    }

    &__Reset {
        border: none;
        background-color: $colorGrey100;
        width: 50px;
        background-image: url('#{$basepath}svg/icon-search-reset.svg');
        background-repeat: no-repeat;
        background-size: 35%;
        background-position: center;
        cursor: pointer;
    }

    &__Suggestions {
        display: none;
        position: absolute;
        width: calc(100% - 16px);
        border-radius: 2px;
        top: 75px;
        background-color: #fff;
        z-index: $z-above-dimmed;

        @include media(m) {
            width: 100%;
        }

        &--HasSuggestions {
            display: block;
        }
    }

    &__Link {
        @extend %navigation-titles;
        padding: 16px;
        color: $colorBlack70;
        display: block;
        text-decoration: none;
        transition: background-color 0.2s;

        .NavigationSearch__SearchLink & {
            font-weight: normal;
            border-top: 1px solid $colorGrey200;
            display: flex;
            align-items: center;

            &:before {
                content: '';
                display: inline-block;
                border: none;
                width: 16px;
                height: 16px;
                background-image: url('#{$basepath}svg/icon-search.svg');
                background-repeat: no-repeat;
                background-size: contain;
                margin-right: 8px;
            }
        }

        &:first-of-type {
            border-top-right-radius: 2px;
            border-top-left-radius: 2px;
        }

        &:last-of-type {
            border-bottom-right-radius: 2px;
            border-bottom-left-radius: 2px;
        }

        &:hover {
            background-color: $colorGrey200;
        }
        &:focus {
            outline: none;
            background-color: $colorGrey300;
        }
    }
}
