@import 'styles/includes.scss';

.Hero {
    $root: &;
    overflow: hidden;
    height: calc(100vh - 48px - 80px);
    position: relative;

    @include media(m) {
        margin: 24px 24px 0 24px;
    }

    &--HomePage {
        height: calc(100vh - 24px - 80px);
        @include media(m) {
            margin: 0 24px 0 24px;
        }
    }

    &--NoCaption {
        margin-bottom: 80px;
    }

    &--BelowBreadcrumbs {
        margin-top: 0;
    }

    &--Clean {
        height: 435px;

        @include media(m) {
            height: calc(100vh - 48px - 80px);
        }
    }

    &__Container {
        height: 100%;
        width: 100%;
        position: relative;
    }

    &__Content {
        padding: 24px;
        opacity: 0;
        transition: 1s;
        transition-delay: 0.5s;
        width: 100%;
        height: 100%;
        z-index: 10;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: absolute;
        top: 0;
        left: 0;

        #{$root}--ImageLoaded & {
            opacity: 1;
        }
    }

    &__Title {
        @extend %h1;
        @extend %container-text;
        transform: initial;
        color: #fff;
        text-shadow: 0 0 12px rgba(0, 0, 0, 0.24);
        text-align: center;
    }

    &__Logo {
        position: relative;
        width: 100%;
        height: 100px;

        @include media(m) {
            height: 160px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &__Text {
        @extend %h2;
        color: #fff;
        text-shadow: 0 0 12px rgba(0, 0, 0, 0.24);
        text-align: center;

        #{$root}--Campaign & {
            color: $colorYellow;
        }

        @include media(m) {
            margin-top: 16px;
        }
    }

    &__CTA {
        text-align: center;
        margin-top: 46px;

        a {
            padding: 15px 8px;
        }
    }

    &__Credits {
        @extend %img-caption;
        padding: 0 16px 0 16px;
        margin-top: 8px;
        margin-bottom: 40px;

        @include media(m) {
            margin-top: 4px;
            padding: 0 24px 0 24px;
            margin-bottom: 80px;
        }
    }

    &__Video {
        height: 100%;
        overflow: hidden;
        padding: 0;
        position: relative;
        opacity: 0;
        transition: opacity 0.2s ease-in-out;

        &--Playing {
            opacity: 1;
        }
    }

    &__VideoPlayer iframe {
        box-sizing: border-box;
        height: 56.25vw !important;
        left: 50%;
        min-height: 100%;
        min-width: 100%;
        transform: translate(-50%, -50%);
        position: absolute;
        top: 50%;
        width: 177.77777778vh !important;
        z-index: 3;
    }

    &__ScrollDown {
        position: absolute;
        bottom: 35px;
        left: 50%;
        transform: rotate(90deg) translateY(50%);

        width: 48px;
        height: 48px;

        box-shadow: $boxShadowStyle;
        background-color: #ffffff;
        border-radius: 50%;

        cursor: pointer;
        border: none;

        opacity: 0.7;

        z-index: 11;

        // &:hover {
        //     background-color: $colorGrey200;
        // }
        &:focus {
            outline: none;
            opacity: 1;
        }
        transition: background-color $duration $ease;

        &__Icon > svg {
            width: 30%;
            height: 30%;
            animation: ScrollDownAnimation 2.5s infinite;
            transform: translateY(1px) translateX(0);
            // animation-timing-function: linear;
            animation-fill-mode: both;
        }

        @keyframes ScrollDownAnimation {
            20% {
                opacity: 1;
                transform: translateY(1px) translateX(0);
            }
            100% {
                opacity: 0;
                transform: translateY(1px) translateX(12px);
            }
        }
    }

    &__MapImageWrapper {
        position: absolute;
        bottom: 0;
        width: 100%;
        background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(0, 0, 0, 0.5) 100%
        );
    }

    &__MapImage {
        @extend %container;

        figure {
            margin-bottom: 8px;

            @include media(m) {
                margin-left: 8px;
                margin-bottom: 36px;
            }
        }

        img {
            height: 150px;
            margin-bottom: 4px;

            @include media(m) {
                height: 200px;
            }
        }

        figcaption {
            color: #fff;
            font-family: $fontSwedenSans;
            font-size: 1.2rem;
            font-weight: bold;
            text-transform: uppercase;

            @include media(m) {
                font-size: 1.4rem;
            }
        }
    }

    &--Campaign {
        height: calc(100vh - 24px - 80px);
        margin: 0;

        #{$root}__ScrollDown {
            bottom: 80px;
        }

        #{$root}__Title {
            @extend %container;
            padding: 0;
            font-size: 3.7rem;

            @include media(m) {
                font-size: 5rem;
                padding: 0 40px;
            }

            @include media(l) {
                font-size: 7rem;
                padding: 0 40px;
            }

            @include media(xl) {
                font-size: 8rem;
            }
        }
    }

    &--HideNavigation {
        height: 100vh;

        // Overlay for the basic logo
        &:after {
            content: '';
            position: absolute;
            top: 0;
            height: 140px;
            width: 100%;
            background: linear-gradient(
                180deg,
                rgba(0, 0, 0, 0.3) 0%,
                rgba(0, 0, 0, 0) 80%
            );
        }
    }

    &--AlignBottom #{$root}__Content {
        justify-content: flex-end;
        padding-bottom: 150px;
    }
}
