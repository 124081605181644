@import 'styles/includes.scss';

.SearchDropdown {
    $root: &;

    position: relative;
    margin-left: auto;

    &__Wrap {
        display: none;
        position: absolute;
        top: 80px;
        box-shadow: $boxShadowCard;
        max-height: 300px;
        z-index: 10;
        background-color: #fff;

        #{$root}--HideSubLabel & {
            top: 50px;
        }

        &--Open {
            display: block;
            overflow: auto;
        }
    }

    &__SubLabel {
        display: block;
        @extend %small-labels;
        margin-bottom: 8px;
        margin-top: 16px;

        #{$root}--HideSubLabel & {
            // sr-only
            border: 0 !important;
            clip: rect(1px, 1px, 1px, 1px) !important;
            -webkit-clip-path: inset(50%) !important;
            clip-path: inset(50%) !important;
            height: 1px !important;
            margin: -1px !important;
            overflow: hidden !important;
            padding: 0 !important;
            position: absolute !important;
            width: 1px !important;
            white-space: nowrap !important;
            text-transform: none;
        }
    }

    &__Button {
        @extend %navigation-sub-titles;
        background-color: $colorGrey100;
        padding: 10px 40px 10px 20px;
        border: none;
        position: relative;

        &:after {
            content: '';
            position: absolute;
            display: block;

            right: 10px;
            top: 12px;

            width: 16px;
            height: 16px;

            background-size: contain;
            background-image: url('#{$basepath}svg/icon-dropdown.svg');
        }
    }

    &__Checkbox {
        border: 2px solid rgba($colorBlack, 0.7);
        position: absolute;
        left: 12px;
        top: 50%;
        transform: translateY(-50%);
        display: block;
        height: 16px;
        width: 16px;

        &--Active {
            &:before {
                content: '';
                background-color: $colorYellow;
                position: absolute;
                left: 2px;
                top: 50%;
                transform: translateY(-50%);
                display: block;
                height: 8px;
                width: 8px;
            }
        }
    }

    &__Choice {
        @extend %body-copy-small;
        text-decoration: none;
        border-bottom: 1px solid $colorGrey200;
        margin-bottom: 0;
        position: relative;
        display: block;
        white-space: nowrap;

        &:hover {
            background-color: $colorGrey200;
        }
    }

    &__ChoiceButton {
        padding: 13px 16px 8px 40px;
        width: 100%;
        text-align: left;

        &:focus {
            background-color: $colorGrey300;
        }
    }
}
