@import 'styles/includes.scss';

.ArticlePage {
    $root: &;

    &__Actions {
        @extend %container-text;
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;

        @include media(m) {
            flex-direction: row;
            margin-bottom: 80px;
        }
    }

    &--HasMap {
        .BookmarkButton .Button {
            height: 56px;
        }
    }

    &__Main {
        @extend %container;
        position: relative;
        padding: 0;
    }

    &__Container {
        @extend %container-text;
    }

    &__Links {
        margin-bottom: 24px;

        @include media(l) {
            margin-bottom: 48px;
            position: absolute;
            width: 212px;
            top: 52px;
            right: 0;
        }

        @include media(xl) {
            top: 60px;
        }

        &--Mobile {
            @include media(l) {
                display: none;
            }
        }

        &--Desktop {
            display: none;

            @include media(l) {
                display: block;
            }
        }
    }

    &__Title {
        #{$root}--Imageless & {
            margin-top: 20px;
        }
        margin-top: 40px;
        margin-bottom: 8px;
        @extend %h2;
    }

    &__Preamble {
        @extend %preamble;
        @extend %rich-preamble;
        margin-bottom: 14px;
    }
}
