@import 'styles/includes.scss';

.ArticleList {
    $root: &;

    overflow: hidden;

    margin-bottom: 40px;

    @include media(m) {
        margin-bottom: 80px;
    }

    &--HasIndicator {
        margin-bottom: 30px;

        @include media(m) {
            margin-bottom: 70px;
        }
    }

    &__TextContainer {
        position: relative;

        &:hover .ArticleList__ShowMore:after {
            right: 0;
        }

        #{$root}--Northern &,
        #{$root}--Southern &,
        #{$root}--Central & {
            min-height: 80px;
            padding-left: 50px;

            &:after {
                content: '';
                display: block;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                height: 100%;
                width: 50px;
                background-size: contain;
                background-position: center center;
                background-repeat: no-repeat;
            }
        }

        #{$root}--Northern & {
            &:after {
                background-image: url('#{$basepath}svg/icon-sweden-north.svg');
            }
        }
        #{$root}--Southern & {
            &:after {
                background-image: url('#{$basepath}svg/icon-sweden-south.svg');
            }
        }
        #{$root}--Central & {
            &:after {
                background-image: url('#{$basepath}svg/icon-sweden-middle.svg');
            }
        }
    }

    &__ShowMoreText {
        visibility: hidden;

        @include media(m) {
            visibility: visible;
        }
    }

    &__ShowMore {
        @extend %body-copy-small;
        .ArticleList--NonPremium & {
            font-size: 1.4rem;
        }

        text-transform: uppercase;
        padding-right: 32px;
        font-weight: 600;
        display: inline-flex;
        align-self: center;
        position: absolute;
        right: 0;
        top: 0;

        @include media(m) {
            top: unset;
            bottom: 6px;
        }

        &:after {
            content: '';
            display: block;
            position: absolute;
            right: 5px;
            top: 50%;
            transform: translateY(-50%);
            height: 12px;
            width: 14px;
            background-size: contain;
            background-image: url('#{$basepath}svg/icon-arrow.svg');
            transition: right 0.3s ease-in-out;
        }
    }

    &__Title {
        .ArticleList--NonPremium & {
            @extend %small-labels-semibold;
            text-transform: uppercase;
            margin-bottom: 4px;
        }

        .ArticleList--Premium & {
            @extend %h4;
        }
        color: $colorGrey800;

        &--Arrow a {
        }

        &--Linked a {
            color: $colorBlack;
            text-decoration: none;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            z-index: 1;
            justify-content: space-between;
        }
    }

    &__Preamble {
        @extend %img-caption;
        margin-bottom: 12px;
        max-width: 700px;
    }

    &__Line {
        margin-bottom: 24px;
        height: 2px;
        width: 100%;
        background-color: $colorBlack12;
    }

    &__Container {
        @extend %container;
        overflow: visible;
        position: relative;
    }

    &__SlideWrap {
        position: relative;
        margin-top: 50px;
    }

    &__Slidable {
        position: relative;
        max-width: 100%;
        min-width: 100%;
        height: auto;
        overflow-x: auto;
        overflow-y: hidden;
        display: flex;

        top: -50px;
        padding-top: 30px;
        padding-bottom: 50px;
        margin-bottom: -100px;
        scroll-behavior: smooth;

        @include ie-9-plus() {
            margin-bottom: -115px;
        }

        @include edge-only() {
            margin-bottom: -115px;
        }
    }

    &__Image {
        position: relative;
        padding-top: 56.25%;
        width: 100%;

        #{$root}--Narrow & {
            padding-top: 62.15%;
        }

        .BackgroundImage__Image {
            border-top-right-radius: $cardBorderRadius;
            border-top-left-radius: $cardBorderRadius;
        }
    }

    &__Card {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        width: calc(100% - 40px);
        align-items: stretch;
        flex-shrink: 0;
        margin-right: 11px;
        // flex-grow: 1;
        background-color: $colorGrey200;
        align-content: flex-start;
        transition: background-color $duration $ease;
        text-decoration: none;

        border-radius: $cardBorderRadius;

        &:focus {
            outline: none;
        }

        @include media(m) {
            width: calc(50% - 12px);
            margin-right: 24px;
        }

        @include media(l) {
            width: calc(25% - 18px);
        }

        #{$root}--Narrow & {
            width: calc(100% - 40px);

            @include media(m) {
                width: calc(33.33% - 16px);
            }
        }

        #{$root}--Single & {
            width: 100%;

            @include media(m) {
                width: calc(50% - 12px);
                flex: 0 0 auto;
            }

            @include media(l) {
                width: calc(25% - 18px);
                flex: 0 0 auto;
            }
        }
    }

    &__CardContent {
        min-height: 64px;
        width: 100%;
        overflow: hidden;
        border-bottom-left-radius: $cardBorderRadius;
        border-bottom-right-radius: $cardBorderRadius;
    }

    &__CardTitle {
        width: 100%;
        height: 100%;
        position: relative;

        // background-color: $colorGrey200;

        padding: 12px 20px;

        color: $colorBlack;

        @extend %small-labels;

        .ArticleList__Card--city &,
        .ArticleList__Card--region &,
        .ArticleList__Card--external & {
            padding-left: 44px;
            &:after {
                content: '';
                position: absolute;
                top: 10px;
                left: 20px;
                background-size: contain;
                width: 16px;
                height: 16px;
            }
        }

        .ArticleList__Card--external & {
            &:after {
                background-image: url('#{$basepath}svg/icon-diagonal-arrow.svg');
            }
        }

        .ArticleList__Card--city & {
            &:after {
                background-image: url('#{$basepath}svg/icon-map-marker.svg');
            }
        }

        .ArticleList__Card--region & {
            &:after {
                background-image: url('#{$basepath}svg/icon-region.svg');
            }
        }
    }

    &__Tag {
        font-size: 1rem;
    }

    &__Link {
        @extend %small-labels;
        text-decoration: none;
        color: black;
        display: block;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        z-index: 1;
    }

    &__Card:hover {
        background-color: $colorBlack12;
    }

    &__Card:focus {
        background-color: $colorBlack20;
    }

    &__ArrowRight,
    &__ArrowLeft {
        position: absolute;
        right: -16px;
        top: 46%;

        width: 40px;
        height: 40px;

        box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.3);
        background-color: #fff;
        transition: background-color $duration $ease;
        z-index: $z-front;

        &:hover {
            background-color: $colorGrey200;
        }

        &:focus {
            outline: none;
            background-color: $colorGrey300;
        }

        border-radius: 50%;
        cursor: pointer;
        border: none;
        display: none;

        @include media(m) {
            display: block;
        }

        &:after {
            content: '';
            display: block;

            width: 16px;
            height: 16px;

            margin: 0 auto;
            background-size: contain;
            background-position-x: 1px;
            background-image: url('#{$basepath}svg/icon-chevron-right.svg');
        }
    }

    &__ArrowLeft {
        right: auto;
        left: -16px;

        &:after {
            transform: rotateZ(-180deg);
        }
    }

    &__Pager {
        text-align: right;
    }

    &__PagerBg {
        display: inline-block;
        position: relative;
        width: auto;
        margin-top: 16px;
        height: 4px;
        background-color: $colorBlack24;
        width: 60px;
    }

    &__PagerContent {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.7);
    }
}
