@import 'styles/includes.scss';

.Loader {
    $root: &;

    color: $colorGrey200;
    font-size: 10px;
    margin: 0 auto 65px;
    position: relative;
    text-indent: -9999em;
    transform: translateZ(0);
    animation-delay: -0.16s;

    &--Blue {
        color: $colorDawnBlue;
    }

    &--Yellow {
        color: $colorPaleYellow;
    }

    &,
    &::before,
    &::after {
        border-radius: 50%;
        width: 2.5em;
        height: 2.5em;
        animation-fill-mode: both;
        animation: load7 1.8s infinite ease-in-out;
    }

    &::before,
    &::after {
        content: '';
        position: absolute;
        top: 0;
    }

    &::before {
        left: -3.5em;
        animation-delay: -0.32s;
    }

    &::after {
        left: 3.5em;
    }
}

@keyframes load7 {
    0%,
    80%,
    100% {
        box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
        box-shadow: 0 2.5em 0 0;
    }
}
