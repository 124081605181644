@import 'styles/includes.scss';

.MapInfoWindow {
    $root: &;

    width: 320px;
    background-color: white;
    border-radius: 2px;
    box-shadow: $boxShadowCard;
    position: relative;

    &__Link {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        z-index: 1;

        &:focus {
            outline-offset: -2px;
        }
    }

    &__Container {
        display: flex;
        justify-content: flex-start;
        width: 100%;
    }

    &__Content {
        flex: 1 0 auto;
        width: calc(100% - 95px);
        padding: 12px 36px 12px 10px;
        position: relative;
        background-color: white;
    }

    &__Image {
        flex: 0 0 auto;
        position: relative;
        width: 95px;
        border-radius: 2px;

        &::before {
            content: '';
            display: block;
            width: 100%;
            height: 0;
            padding-bottom: 100%;
        }
    }

    &__Text {
        @extend %small-labels;
        font-weight: normal;
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        @supports (-webkit-line-clamp: 2) {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: initial;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }

    &__ReadMore {
        margin-top: 8px;
        position: relative;
        font-size: 1.6rem;
        font-weight: 600;

        &::after {
            content: '';
            display: inline-block;
            position: relative;
            top: 2px;
            width: 14px;
            height: 14px;
            margin-left: 12px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
            background-image: url('#{$basepath}svg/icon-arrow.svg');
            transition: transform 0.3s ease-in-out;

            #{$root}--External & {
                width: 16px;
                height: 16px;
                margin-left: 10px;
                background-image: url('#{$basepath}svg/icon-diagonal-arrow.svg');
            }

            #{$root}:hover & {
                transform: translateX(5px);
            }
        }
    }
}
