@import 'styles/includes.scss';

.SearchDropdownRegion {
    max-width: 290px;
    width: 100%;

    @include media(m) {
        margin-left: auto;
    }
}
