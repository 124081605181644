@import 'styles/includes.scss';

.CookieOverlay {
    $root: &;

    background-color: #f0f4fd;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    &--Map,
    &--VideoSegment {
        position: absolute;
        top: 0;
        z-index: 101;
    }

    &__Text {
        color: black;
        font-family: $fontSourceSansPro;
        max-width: 600px;
        text-align: center;
        line-height: 1.4;
        font-size: 1.3rem;
        margin: 0 10px 5px;

        @include media(s) {
            font-size: 1.6rem;
        }

        @include media(m) {
            font-size: 1.8rem;
            margin: 0 20px 25px;
        }
    }
}
