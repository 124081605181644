@import 'styles/includes.scss';

.ImageCaption {
    $root: &;

    background-color: #fff;
    &:hover:not(&--Open) &__Button {
        background-color: $colorBlack08;
    }

    display: inline-flex;
    position: absolute;
    z-index: 100;
    bottom: 8px;
    right: 8px;
    flex-wrap: wrap;
    flex-direction: column;
    max-width: calc(100% - 16px);

    &--Open {
        padding: 32px;
    }

    &__Title {
        @extend %small-labels-semibold;
        max-width: 400px;
        margin-bottom: 4px;
        color: $colorBlack70;
    }

    &__Caption {
        @extend %img-caption;
        max-width: 400px;
        margin-bottom: 4px;
        color: $colorBlack70;
    }

    &__Credits {
        @extend %img-caption;
        max-width: 400px;
        color: $colorBlack70;
    }

    &__Wrap {
        display: none;

        #{$root}--Open & {
            display: block;
        }
    }

    &__Button {
        @extend %h5;
        border: none;
        height: 32px;
        width: 32px;

        transition: background-color $duration $ease;

        &:focus {
            outline: none;
            background-color: $colorBlack20;
        }

        #{$root}--Open & {
            position: absolute;
            top: 0;
            left: 0;

            height: 17px;
            width: 17px;
            padding: 2px;

            margin: 8px;
            background-size: contain;
            background-image: url('#{$basepath}svg/icon-close.svg');

            margin-bottom: -2px;
        }
    }

    &__Icon {
        color: $colorBlack70;
    }
}
