@import 'styles/includes.scss';

.ArticleDate {
    @extend %container;
    margin-bottom: 32px;

    @include media(l) {
        max-width: 1046px;
    }

    &__Item {
        display: inline-block;
        @extend %small-labels;

        &:nth-of-type(2) {
            padding-left: 10px;
            margin-left: 10px;
            border-left: 1px solid rgba(0, 0, 0, 0.6);
        }
    }

    &__Label {
        font-weight: 600;

        &::after {
            content: ':';
        }
    }
}
