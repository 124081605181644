@import 'styles/includes.scss';

.MapExpander {
    $root: &;

    flex-grow: 1;

    &__Button {
        position: relative;
        display: flex;
        justify-content: space-between;
        text-align: left;
        align-items: center;
        background-color: $colorGrey100;
        width: 100%;
        height: 56px;
        padding-left: 16px + 16px + 8px;

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 16px;
            transform: translateY(-50%);
            background-image: url('#{$basepath}svg/icon-map-marker.svg');
            background-size: contain;
            background-repeat: no-repeat;
            height: 16px;
            width: 16px;
        }

        &[aria-expanded='true'] {
            &:after {
                background-image: url('#{$basepath}svg/icon-minus.svg');
            }
        }

        &:after {
            content: '';
            display: block;
            width: 40px;
            height: 40px;
            background-color: $colorGrey300;
            border-radius: 50%;
            background-repeat: no-repeat;
            background-position: center center;
            background-image: url('#{$basepath}svg/icon-plus.svg');
            margin-right: 16px;
        }
    }
}
