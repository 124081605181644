@import 'styles/includes.scss';

.Map {
    $root: &;

    @extend %container-text;
    margin-bottom: 80px;

    &__Container {
        position: relative;
        margin: 0 auto;
        width: 100%;
        height: 100%;
        padding-bottom: 56.25%;

        & > div {
            position: absolute !important;
            top: 0;

            width: 100%;
            height: 100%;
            overflow: hidden;
            padding: 0;
        }
    }

    &__Poi {
        height: 16px;
        width: 16px;
        background-color: $colorYellow;
        border-radius: 50%;
        position: relative;
        transform: translateX(-8px);
    }

    &--InExpander {
        padding: 0;
        margin-bottom: 0;

        @include media(l) {
            transform: translateX(0);
            max-width: 100%;
        }

        @include media(xl) {
            transform: translateX(0);
            max-width: 100%;
        }
    }
}
