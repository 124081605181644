@import 'styles/includes.scss';

.ButtonToggle {
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center center;
    transition: transform 0.2s;

    &--Grey300 {
        background-color: $colorGrey300;
    }

    &--Plus {
        background-image: url('#{$basepath}svg/icon-plus.svg');
    }

    &--Minus {
        background-image: url('#{$basepath}svg/icon-minus.svg');
    }

    &--Language {
        background-image: url('#{$basepath}svg/icon-chevron-right.svg');
        background-size: 30%;
        transform: rotate(90deg);
        background-position-x: 55%;
    }

    &--LanguageClose {
        background-image: url('#{$basepath}svg/icon-chevron-right.svg');
        background-size: 30%;
        transform: rotate(-90deg);
    }
}
