@import 'styles/includes.scss';

.GlobalSiteSwitcher {
    background-color: #f5f5f5;

    &__Content {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 0 auto;

        @include media(m) {
            height: 36px;
            justify-content: flex-end;
            flex-direction: row;
            align-items: center;
            padding: 0 15px;
        }
        @include media(l) {
            max-width: (map-get($maxwidths, l));
            padding: 0;
        }
        @include media(xl) {
            max-width: (map-get($maxwidths, xl));
        }
    }

    &__Button {
        display: flex;
        justify-content: space-between;
        padding: 16px 5px 16px 16px;
        margin: 2px;
        cursor: pointer;

        @include media(m) {
            cursor: default;
        }
    }

    &__Label {
        font-family: $fontSwedenSans;
        font-weight: 600;
        font-size: 1.3rem;
    }

    &__Arrow {
        width: 17px;
        height: 17px;
        background-image: url('#{$basepath}svg/icon-dropdown-thin.svg');

        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;

        &--Open {
            transform: rotate(180deg);
        }

        @include media(m) {
            display: none;
        }
    }

    &__Items {
        display: none;
        flex-direction: column;
        align-items: flex-start;
        padding-bottom: 20px;

        &--Open {
            display: flex;
        }

        @include media(m) {
            display: flex;
            flex-direction: row;
            padding-bottom: 0;
        }
    }

    &__Link {
        display: inline-block;
        font-family: $fontSwedenSans;
        font-size: 1.3rem;
        color: #464646;
        text-decoration: none;
        padding: 10px 18px 10px 16px;
        position: relative;

        &:after {
            content: '';
            display: block;
            position: absolute;
            min-width: 13px;
            min-height: 13px;
            right: 0;
            bottom: 14px;
            margin-left: 3px;
            margin-right: 1px;
            background-image: url(#{$basepath}svg/icon-external-link-black.svg);
            background-repeat: no-repeat;
            background-size: contain;
        }
    }
}
