@import 'styles/includes.scss';

$trans-duration: 300ms;
$trans-easing: ease-out;

$initial-y-offset: -100%;

.SlideInFromTop {
    &-enter {
        z-index: $z-behind;
        transform: translate3d(0, $initial-y-offset, 0);
    }

    &-enter-active {
        transform: translate3d(0, 0, 0);
        transition: transform $trans-duration $trans-easing;
    }

    &-exit {
        transform: translate3d(0, 0, 0);
    }

    &-exit-active {
        z-index: $z-behind;
        transform: translate3d(0, $initial-y-offset, 0);
        transition: transform $trans-duration $trans-easing;
    }
}
