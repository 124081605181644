@import 'styles/includes.scss';

$trans-duration: 300ms;
$trans-easing: ease-out;

.ScaleVerticallyFadeIn {
    &-enter {
        // max-height: 0;
        opacity: 0;
    }

    &-enter-active {
        opacity: 1;
        // max-height: 300px;
        transition: opacity $trans-duration $trans-easing,
            max-height $trans-duration $trans-easing;
    }

    &-exit {
        opacity: 1;
        // max-height: 300px;
    }

    &-exit-active {
        opacity: 0;
        // max-height: 0;
        transition: opacity $trans-duration $trans-easing,
            max-height $trans-duration $trans-easing;
    }
}
