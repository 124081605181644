@import 'styles/includes.scss';

.CampaignSection {
    background-color: #fff;
    border-color: $colorBlack70;
    color: $colorBlack70;
    padding: 50px 0;

    @include media(l) {
        padding: 60px 0;
    }

    &--BlackOnWhite {
        background-color: #fff;
    }

    &--BlackOnYellow {
        background-color: $colorPaleYellow;
        color: $colorDawnBlue;
    }

    &--WhiteOnBlue {
        background-color: $colorDawnBlue;
        color: #fff;
        border-color: #fff;
    }

    &--YellowOnBlue {
        background-color: $colorDawnBlue;
        color: $colorPaleYellow;
        border-color: $colorPaleYellow;
    }

    &--BlueOnYellow {
        background-color: $colorPaleYellow;
        color: $colorBlue;
        border-color: $colorBlue;
    }

    &__Heading {
        @extend %container;
        @extend %h2;

        margin-bottom: 24px;

        &,
        a {
            color: inherit;
        }
    }
}
