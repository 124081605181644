@import 'styles/includes.scss';

.CrisisInformationBar {
    position: absolute;
    width: 100%;
    height: auto;

    background-color: $colorYellow;
    color: $colorBlack;

    padding-top: 24px;
    padding-bottom: 24px;

    @include media(l) {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    &__Container {
        position: relative;
        @extend %container;

        display: flex;
        flex-direction: column;

        font-size: 0.9em;
        text-align: center;

        @include media(l) {
            flex-direction: row;
        }
    }

    &__Icon {
        flex-shrink: 0;

        align-self: center;

        display: block;
        width: 56px;
        height: 56px;
        margin-bottom: 24px;

        border-radius: 140px;

        background-color: $colorBlack;
        background-image: url('#{$basepath}svg/icon-warning.svg');
        background-size: 35%;
        background-position: 50% 45%;
        background-repeat: no-repeat;

        @include media(l) {
            align-self: flex-start;
            margin-bottom: 0;
        }
    }

    &__Content {
        text-align: left;
        margin-bottom: 24px;

        @include media(l) {
            padding-left: 32px;
            width: calc(100% - 140px);
            padding-right: 160px;
            margin-bottom: 0;
        }

        &__Title {
            @extend %h4;
            margin-bottom: 8px;
        }

        &__Text {
            a {
                color: $colorBlack70;
            }
        }
    }

    &__Close {
        flex-shrink: 0;
        align-self: center;
    }
}
