@import 'styles/includes.scss';

.SearchResults {
    $root: &;
    margin-bottom: 40px;
    margin-top: 24px;

    @include media(m) {
        margin-top: 40px;
        margin-bottom: 80px;
    }

    &__Wrapper {
        @extend %container;
    }

    &__Actions {
        display: flex;
        justify-content: space-between;
        margin-bottom: 24px;
    }

    &__Results {
        display: flex;
        flex-flow: row wrap;
        margin-left: -10px;

        @include media(m) {
            margin-left: -24px;
        }
    }

    &__Info {
        @extend %small-labels-semibold;
        text-transform: uppercase;
        border-bottom: 2px solid $colorBlack12;
        padding-bottom: 8px;
        margin-bottom: 24px;
    }
}
