@import 'styles/includes.scss';

.BasePage {
    &__SkipMain {
        left: -999px;
        position: absolute;
        top: auto;
        width: 1px;
        height: 1px;
        overflow: hidden;
        z-index: -999;

        &:focus,
        &:active {
            @extend %body-copy;
            color: #fff;
            background-color: rgb(76, 76, 76);
            text-decoration: none;
            left: 24px;
            top: 21px;
            width: auto;
            height: auto;
            padding: 5px 32px;
            border-radius: 2px;
            z-index: 9999;
            outline: none;
        }
    }
}
