@import 'styles/includes.scss';

.LinkList {
    &__Heading {
        @extend %small-labels-semibold;
        margin-bottom: 16px;
        padding-bottom: 8px;
        border-bottom: 2px solid $colorBlack12;
    }

    &__Links {
        position: relative;
        padding: 8px 0;

        &::before {
            content: '';
            position: absolute;
            top: 8px;
            bottom: 8px;
            left: 0;
            width: 4px;
            background: $colorYellow;
        }
    }

    &__Link {
        position: relative;
        display: block;
        padding-left: 48px;
        margin-bottom: 16px;
        text-decoration: none;

        &:focus {
            outline: 2px solid $colorBlack70;
        }

        &--HasDescription {
            margin-bottom: 8px;
        }

        &--Link {
            &::before {
                content: '';
                position: absolute;
                min-width: 16px;
                min-height: 16px;
                display: block;
                top: 4px;
                left: 20px;
                background-image: url('#{$basepath}svg/icon-diagonal-arrow.svg');
                background-repeat: no-repeat;
                background-size: contain;
            }

            // Add external link styling if href containes visitsweden
            &:not([href*='visitsweden']),
            &[href*='corporate.visitsweden'],
            &[href*='traveltrade.visitsweden'] {
                &::before {
                    background-image: url('#{$basepath}svg/icon-external-link.svg');
                }
            }
            // Remove external link styling if href starts with #
            &[href^='\#'] {
                &::before {
                    background-image: url('#{$basepath}svg/icon-diagonal-arrow.svg');
                }
            }
        }

        &--Document {
            &::before {
                content: '';
                position: absolute;
                width: 16px;
                height: 16px;
                top: 4px;
                left: 16px;
                background: url('#{$basepath}svg/icon-download.svg') no-repeat;
            }
        }
    }

    &__Link:hover &__Title {
        color: $colorBlack;
    }

    &__Title {
        @extend %standard-link-small;
        display: block;
        margin-bottom: 4px;

        color: $colorBlack70;
        transition: color $duration $ease;
    }

    &__Description {
        @extend %img-caption;
        color: $colorBlack70;
        display: inline-block;
    }
}
