@import 'styles/includes.scss';

$cardImageSize: 100px;

.BookmarkCard {
    $root: &;

    position: relative;
    width: 100%;
    height: $cardImageSize;

    margin-bottom: 16px;
    &:last-child {
        margin-bottom: 40px;
    }

    &:not(&--HasButtonHover):hover &__Content {
        background-color: $colorBlack08;
    }

    &__Link {
        position: absolute;
        display: block;

        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        text-decoration: none;
        color: $colorBlack70;

        &:focus {
            outline: none;
        }

        &:focus #{$root}__Content {
            background-color: $colorBlack16;
        }
    }

    &__Container {
        width: 100%;
        height: 100%;

        overflow: hidden;

        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: stretch;
        align-content: stretch;

        box-shadow: $boxShadowCard;
    }

    &__Image {
        position: relative;

        width: $cardImageSize;
        height: 100%;

        background-color: $colorGrey300;
    }

    &__Content {
        position: relative;
        width: calc(100% - #{$cardImageSize});
        height: 100%;
        padding: 16px;

        background-color: white;
        transition: background-color $duration $ease;
    }

    &__Title {
        @extend %small-labels-semibold;
    }

    &__Button {
        position: absolute;
        bottom: 8px;
        right: 16px;
    }
}
