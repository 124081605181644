@import 'styles/includes.scss';

.HomePage {
    &__Preamble {
        p {
            @extend %h3;
            max-width: map-get($maxwidths, 'content');
            margin: 0 auto 40px;
            width: 100%;
            padding: 0 16px;
            margin-bottom: 20px;

            @include media(m) {
                margin: 0 auto 80px;
            }
        }

        a {
            color: $colorBlack70;
            text-decoration: underline;
        }
    }
}
