@import 'styles/includes.scss';

$mainMenuDesktopHeight: 160px;

.Footer {
    $root: &;

    width: 100%;
    height: auto;

    background-color: $colorYellow;
    margin-top: 24px;

    @include media(m) {
        margin-top: 40px;
    }

    @include media(l) {
        margin-top: 60px;
    }

    &__Container {
        @extend %container;
        width: 100%;

        display: flex;
        align-items: flex-start;
        flex-direction: column;
        @include media(l) {
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
        }

        padding-top: 24px;
    }

    &__SocialNav {
        width: calc(100% - 33.5 * 2px);
        order: 0;

        @include media(l) {
            margin-left: 16px;
            order: initial;

            width: 35%;
            height: $mainMenuDesktopHeight;

            margin: 0;
            padding-bottom: 32px;

            border-bottom: 1px solid rgba(black, 0.15);
        }

        &__Heading {
            @extend %small-labels;
            font-weight: 600;

            margin-top: 24px;
            padding-bottom: 5px;
            @include media(l) {
                margin-top: 0;
                padding-bottom: 16px;
            }

            text-transform: uppercase;

            @include media(l) {
                border-bottom: none;
            }
        }

        &__Container {
            margin-left: 0;

            @include media(m) {
                margin-left: 0;
            }

            @include media(l) {
                margin-left: -22px;
            }
        }

        &__Link {
            display: inline-block;

            text-decoration: none;

            width: 48px;
            height: 48px;

            padding: 8px;
            margin: 8px 4px;

            &:first-of-type {
                margin-left: 0;
            }

            @include media(tabletL) {
                margin: 0 12px;

                &:first-of-type {
                    margin-left: 0;
                }
            }

            @include media(l) {
                margin: 0 12px;

                &:first-of-type {
                    margin-left: 12px;
                }
            }

            &:focus {
                outline: none;
                background-color: $colorYellow08;
            }

            transition: background-color $duration $ease;

            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            background-origin: content-box;

            &--Instagram {
                background-image: url('#{$basepath}svg/social-instagram.svg');
            }

            &--Youtube {
                background-image: url('#{$basepath}svg/social-youtube.svg');
            }

            &--Facebook {
                background-image: url('#{$basepath}svg/social-fb.svg');
            }

            &--Weibo {
                background-image: url('#{$basepath}svg/social-weibo.svg');
            }

            &--Wechat {
                background-image: url('#{$basepath}svg/social-wechat.svg');
            }

            &--Pinterest {
                background-image: url('#{$basepath}svg/social-pinterest.svg');
            }

            &--Tiktok {
                background-image: url('#{$basepath}svg/social-tiktok.svg');
            }

            &--Threads {
                background-image: url('#{$basepath}svg/social-threads.svg');
            }
        }
    }

    &__MainNav {
        width: 100%;
        order: 1;

        @include media(l) {
            order: initial;

            display: flex;
            align-items: center;

            width: 65%;
            height: $mainMenuDesktopHeight;

            border-bottom: 1px solid rgba(black, 0.15);
        }

        &__Container {
            width: 100%;

            &:before {
                display: none;
            }

            @include media(l) {
                display: flex;
            }
        }

        &__Item {
            padding-top: 16px;
            padding-left: 16px * 2;
            margin-bottom: 16px;
            margin-left: -16px;
            width: 100vw;

            border-top: 1px solid rgba(black, 0.15);

            @include media(m) {
                margin-left: 0;
                width: auto;
            }

            @include media(l) {
                border: none;

                margin-bottom: 57px;
                padding: 0;
                &:not(:first-of-type) {
                    padding-left: 42px;
                }
            }
        }

        &__Link {
            @extend %body-copy;

            color: $colorBlack70;
            &:hover {
                color: $colorBlack;
            }

            transition: color $duration $ease;

            font-weight: normal;

            text-transform: none;
            text-decoration: none;

            transition: background-color $duration $ease;

            // Add external link styling if href containes visitsweden
            &:not([href*='visitsweden']),
            &[href*='corporate.visitsweden'],
            &[href*='traveltrade.visitsweden'] {
                line-height: 1.1;

                &::after {
                    content: '';
                    display: inline-block;
                    min-width: 16px;
                    min-height: 16px;
                    margin-left: 3px;
                    margin-right: 1px;
                    background-image: url(#{$basepath}svg/icon-external-link.svg);
                    background-repeat: no-repeat;
                    background-size: contain;
                    position: relative;
                    bottom: -1.5px;
                }
            }
            // Remove external link styling if href starts with #
            &[href^='\#'] {
                padding-right: 0;

                &::after {
                    display: none;
                }
            }

            &:focus {
                outline: none;
                background-color: $colorYellow12;
            }
        }
    }

    &__SwedenLogotypes {
        display: flex;

        max-width: 345px;
        width: calc(100% - 24px);
        margin: 32px auto 42px auto;

        border: 3px solid $colorBlue;

        order: 2;

        @include media(l) {
            margin: 40px 0 40px 0;
            width: 35%;
            order: initial;
        }

        &__Logo {
            width: 100%;
            height: 100%;

            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            background-origin: content-box;

            &--Sweden {
                background-image: url('#{$basepath}svg/logotype-sweden.svg');
                padding: 15px 20px;
            }

            &--VisitSweden {
                background-image: url('#{$basepath}svg/logotype-visitsweden-footer.svg');
                padding: 20px 30px;
            }
        }

        &__Link {
            width: 50%;
            min-height: 80px;
            height: 80px;

            &--Second {
                border-left: 3px solid $colorBlue;
            }

            &:focus {
                outline: none;
                background-color: $colorYellow08;
            }

            transition: background-color $duration $ease;
        }
    }

    &__PrivacyNav {
        width: 100%;
        order: 3;
        @include media(l) {
            order: initial;
            width: 65%;
        }

        margin: 0 16px;
        padding: 0;
        @include media(l) {
            padding-top: 32px;
            margin: 0;
        }

        &__Container {
            width: 100%;

            &:before {
                display: none;
            }
        }

        &__Item {
            padding: 0;
        }

        &__Link {
            @extend %standard-link-small;
            transition: background-color $duration $ease;

            &:focus {
                outline: none;
                background-color: $colorYellow08;
            }
        }

        &__CopyrightNotice {
            width: 100%;
            width: calc(100% - 16 * 2px);

            @extend %body-copy-small;

            font-size: 1.5rem;
            line-height: (20/15);

            margin-top: 24px;
            padding-bottom: 48px;
            padding-top: 10px;
            @include media(l) {
                margin-top: 0;
            }
        }
    }
}
