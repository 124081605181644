@import 'styles/includes.scss';

.BackgroundImage {
    $root: &;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;

    &--HasLogo {
        height: calc(100% + 180px);

        @include media(m) {
            height: calc(100% + 260px);
        }
    }

    &__Image {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        left: 0;
        object-fit: cover;
        opacity: 0;
        position: absolute;
        top: 0;
        transition: opacity 1s;
        width: 100%;

        #{$root}--Loaded &,
        #{$root}--Fallback & {
            opacity: 1;
        }
    }

    &__Credits {
        bottom: 12px;
        color: #ffffff;
        font-size: 1.2rem;
        position: absolute;
        right: 12px;
        text-align: right;
        width: 75%;
        z-index: 1;
    }
}
