@import 'styles/includes.scss';

.SearchChips {
    overflow: hidden;
    flex-grow: 1;

    &__Reset {
        float: right;
        position: relative;
        align-items: center;
        font-weight: bold;
        text-transform: uppercase;
        display: inline-flex;

        @include media(m) {
            display: none;
        }
        &:after {
            margin-left: 4px;
            content: '';
            display: inline-block;
            width: 16px;
            height: 16px;
            background-image: url('#{$basepath}svg/icon-trash.svg');
            background-size: contain;
            background-repeat: no-repeat;
        }
    }

    &__ChipsWrap {
        position: relative;
        &:after {
            @include media(m) {
                content: initial;
            }
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            width: 50px;
            height: 100%;
            background: linear-gradient(
                to left,
                rgba(255, 255, 255, 1) 0%,
                rgba(255, 255, 255, 0) 100%
            );
        }
    }

    &__Chips {
        display: flex;
        flex-wrap: nowrap;
        height: 50px;
        overflow-x: scroll;
        padding-right: 24px;

        @include media(m) {
            flex-wrap: wrap;
            overflow: hidden;
            padding-right: 0;
        }

        &--ShowMore {
            height: auto;
        }
    }

    &__Plus {
        display: inline-block;
        width: 10px;
        height: 10px;
        background-repeat: no-repeat;
        background-position: center center;
        background-image: url('#{$basepath}svg/icon-plus.svg');
        margin-left: 10px;
    }

    &__ShowMore {
        @extend %small-labels-semibold;
        font-weight: 600;
        text-transform: uppercase;
        margin-top: 8px;
        margin-bottom: 16px;
    }

    &__SubLabel {
        display: block;
        @extend %small-labels;
        margin-bottom: 8px;
        margin-top: 16px;
    }

    &__Chip {
        @extend %navigation-sub-titles;
        background-color: $colorGrey300;
        color: rgba(0, 0, 0, 0.7);
        border-radius: 2px;
        padding: 10px 20px;
        margin-right: 8px;
        margin-bottom: 8px;
        border: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        flex-shrink: 0;
        transition: background-color 0.2s;

        &:hover {
            background-color: $colorGrey400;
        }

        &:after {
            content: '';
            display: inline-block;
            width: 0px;
            height: 25px;
        }

        &--Active {
            background-color: $colorYellow;
        }

        &--Active:hover {
            background-color: darken($colorYellow, 7);
        }

        &--Active:after {
            content: '';
            display: inline-block;
            width: 25px;
            height: 25px;
            background-image: url('#{$basepath}svg/icon-check.svg');
            background-repeat: no-repeat;
            background-size: 65%;
            margin-left: 8px;
            background-position: center;
            opacity: 0;
            transition: opacity 0.2s ease-in-out;
            opacity: 1;
        }
    }
}
