@import 'styles/includes.scss';

.SearchFilter {
    $root: &;
    padding: 56px 0;

    &--LandingPage {
        padding: 24px 0;
    }

    .LandingPage & {
        padding: 24px 0;
    }

    &--Global {
        background-color: $colorGrey050;
    }

    &__Wrap {
        @extend %container;
    }

    &__Label {
        // The label is invisible but should still be readable by a screen reader
        position: absolute;
        width: 1px;
        height: 1px;
        margin: -1px;
        padding: 0;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        border: 0;
    }

    &__Actions {
        display: flex;
        flex-wrap: wrap;

        @include media(m) {
            flex-wrap: nowrap;
        }
    }

    &__HeadingLabel {
        #{$root}--Global & {
            @extend %h4;
            border-bottom: 2px solid $colorBlack12;
            padding-bottom: 8px;
            margin-bottom: 24px;
        }

        #{$root}--NonGlobal & {
            @extend %small-labels-semibold;
            margin-bottom: 8px;
            text-transform: uppercase;
        }

        display: block;
    }

    &__SubLabel {
        display: block;
        @extend %small-labels;
        margin-bottom: 8px;
        margin-top: 16px;
    }
}
