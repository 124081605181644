@import 'styles/includes.scss';

.NavigationFavorite {
    position: relative;
    display: inline-block;

    height: 80px;
    width: 32px;
    padding-right: 46px;
    margin-right: 16px;

    background-image: url('#{$basepath}svg/icon-favorite.svg');
    background-size: 32px 32px;
    background-repeat: no-repeat;
    background-position: center center;

    opacity: 1;
    transition: opacity 0.15s ease;

    @include media(m) {
        margin-right: 8px;
    }

    &--Fade {
        opacity: 0.4;
    }

    &--Filled {
        background-image: url('#{$basepath}svg/icon-favorite-filled.svg');
    }

    &__NumberContainer {
        position: absolute;
        background: $colorYellow;
        top: 29px;
        right: 6px;
        border-radius: 50%;
        font-size: 1.2rem;
        color: $colorBlack70;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 16px;
        height: 16px;
    }

    &__Number {
        position: absolute;
        top: 50%;
        transform: translateY(-51%);
    }

    &--Active {
        border-bottom: 4px solid $colorYellow;
    }
}
