@import 'styles/includes.scss';

.BackBanner {
    position: fixed;
    bottom: 0;
    left: 0;
    margin: 16px;
    z-index: $z-cookiebar;

    @include media(m) {
        bottom: 32px;
        left: 15px;
    }

    @include media(l) {
        bottom: 16px;
        left: 16px;
    }

    &__Container {
        position: relative;
        width: 300px;
    }

    &__Clickable {
        @extend %navigation-titles;
        display: block;
        width: 100%;
        height: 56px;
        box-shadow: $boxShadowCard;
        display: flex;
        align-items: center;
        color: $colorBlack70;
        text-decoration: none;
        padding-left: 56px + 16px;
        padding-right: 16px;
        background-color: #fff;

        // &--Map {
        //     padding-left: 56px + 16px + 32px;
        //     &:before {
        //         content: '';
        //         position: absolute;
        //         left: 56px;
        //         top: 0;
        //         border: none;
        //         width: 56px;
        //         height: 56px;
        //         background-image: url('#{$basepath}svg/icon-explore.svg');
        //         background-repeat: no-repeat;
        //         background-position: 50% 50%;
        //         background-size: 30%;
        //         border-top-right-radius: 2px;
        //         border-bottom-right-radius: 2px;
        //         transform: rotateZ(180deg);
        //         cursor: pointer;
        //     }
        // }

        &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            border: none;
            background-color: $colorYellow;
            width: 56px;
            height: 56px;
            background-image: url('#{$basepath}svg/icon-arrow.svg');
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: 30%;
            border-top-right-radius: 2px;
            border-bottom-right-radius: 2px;
            transform: rotateZ(180deg);
            cursor: pointer;
        }
    }
}
