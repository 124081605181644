@import 'styles/includes.scss';

.Navigation {
    $root: &;

    border-bottom: 2px solid $colorGrey200;

    .headroom--scrolled & {
        border-bottom: 2px solid $colorGrey200;
    }

    &--HomePage {
        border-bottom: 2px solid #fff;
    }

    background-color: #fff;

    &__Container {
        @extend %container;

        display: flex;
        height: 80px;
        align-items: center;

        &--IsSearching {
            position: relative;
        }
    }

    &__NavItem {
        padding-right: 32px;
        flex-shrink: 0;
        height: 80px;
        display: flex;
        align-items: center;
    }

    &__Logotype {
        background-image: url('#{$basepath}svg/logotype-visitsweden.svg');
        background-size: contain;
        background-repeat: no-repeat;
        width: 184px;
        height: 33px;
        margin-right: 32px;
        position: relative;
        opacity: 1;
        transition: opacity 0.15s ease;

        &--Fade {
            opacity: 0.45;
        }

        a {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    &__Byline {
        @extend %img-caption;
        max-width: 166px;
        opacity: 1;
        transition: opacity 0.15s ease;
        padding-right: 42px;
        line-height: 1.33;
        display: none;

        @media (min-width: 1440px) {
            display: block;
            padding-right: 0;
            line-height: 1.33;
        }

        &--Fade {
            opacity: 0.4;
        }
    }

    &__Menu {
        display: flex;
        margin-left: auto;
        height: 80px;
        align-items: center;
    }

    &__Search {
        margin-right: 16px;
        width: 24px;
        opacity: 0.7;
        position: relative;
        padding: 0 20px;

        flex-shrink: 0;
        height: 80px;
        display: flex;
        align-items: center;

        background-image: url('#{$basepath}svg/icon-search.svg');
        background-size: 24px;
        background-repeat: no-repeat;
        background-position: 50% 50%;

        transition: opacity 0.15s ease;

        &--Active {
            &:before {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 4px;
                // transform: scale(0);
                background: $colorYellow;
            }
        }

        &--Fade {
            opacity: 0.4;
        }
    }

    &__MenuWrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    &__ServiceMenu {
        border-left: 1px solid $colorGrey300;
        padding-left: 16px;
        position: relative;
        top: 0;
        transition: opacity 0.15s ease;

        &:after {
            content: '';
            position: absolute;
            display: block;
            opacity: 0.8;

            right: 0;
            top: 9px;

            width: 12px;
            height: 12px;

            background-size: contain;
            background-image: url('#{$basepath}svg/icon-dropdown.svg');
        }

        &--Fade {
            opacity: 0.4;
        }
    }

    &__ActiveLanguage {
        @extend %body-copy-small;
        border: none;
        padding-right: 16px;
        position: relative;
        text-transform: capitalize;
        z-index: 3;
    }

    &__LanguageSwitcher {
        opacity: 1;
    }

    &__LanguageLink {
        @extend %body-copy-small;
        text-decoration: none;
        border-bottom: 1px solid $colorGrey200;
        margin-bottom: 0;
        padding: 13px 16px 8px 40px;
        position: relative;
        text-transform: capitalize;
        display: block;

        &:focus {
            background-color: $colorGrey300;
            outline: 0;
        }

        &:hover {
            background-color: $colorGrey200;
        }
    }

    &__LanguageMenu {
        position: absolute;
        background-color: #fff;
        top: 30px;
        left: 0;
        box-shadow: $boxShadowCard;
        display: none;

        &--Open {
            display: block;
        }
    }

    &__Checkbox {
        border: 2px solid rgba($colorBlack, 0.7);
        position: absolute;
        left: 12px;
        top: 50%;
        transform: translateY(-50%);
        display: block;
        height: 16px;
        width: 16px;

        &--Active {
            &:before {
                content: '';
                background-color: $colorYellow;
                position: absolute;
                left: 2px;
                top: 50%;
                transform: translateY(-50%);
                display: block;
                height: 8px;
                width: 8px;
            }
        }
    }

    &__NavLink {
        @extend %navigation-titles;
        color: $colorBlack70;
        position: relative;
        text-decoration: unset;
        height: 80px;
        padding-bottom: 2px;
        display: flex;
        align-items: center;
        transition: opacity 0.15s ease;

        &::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 4px;
            transform: scale(0);
            background: $colorYellow;
            transition: transform 0.25s ease;
        }

        &:hover {
            &:after {
                transform: scale(1);
            }
        }

        &--ActivePage {
            &:after {
                transform: scale(1);
            }
        }

        &--Explore {
            position: relative;
            padding-left: 25px;
            padding-right: 32px;

            &:before {
                content: '';
                display: block;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                background-image: url('#{$basepath}svg/icon-explore.svg');
                background-size: contain;
                background-repeat: no-repeat;
                height: 16px;
                width: 16px;
            }
        }

        &--Fade {
            opacity: 0.4;
        }
    }

    @keyframes fadeInMenu {
        0% {
            opacity: 0;
            transform: translateY(-10px);
        }
        100% {
            opacity: 1;
            transform: translateY(0px);
        }
    }

    &__Dropdown {
        position: fixed;
        background-color: $colorGrey100;
        top: 80px;
        left: 0;
        width: 100%;
        z-index: 1000;
        display: none;

        &--Visible {
            display: block;
        }
    }

    &__DropdownWrap {
        display: flex;
        justify-content: flex-end;
        max-width: 1260px;
        margin: 0 auto;
        padding: 48px 0;
    }

    &__DropdownContainer {
        display: flex;
        padding-top: 60px;
        position: relative;
    }

    &__DropdownOuterList {
        width: 302px;
        padding-left: 22px;
        margin-left: 17px;
        flex-shrink: 0;

        display: flex;
        flex-direction: column;

        border-left: 2px solid rgba($colorBlack, 0.12);
        opacity: 0;
        transform: translateY(-10px);

        #{$root}__Dropdown--Visible & {
            animation: fadeInMenu 0.25s 0.15s ease forwards;
        }
    }

    &__DropdownHeader {
        position: absolute;
        top: 0;
        left: 17px;
    }

    &__DropdownHeaderLink {
        position: relative;
        text-decoration: none;

        span {
            @extend %navigation-sub-titles;
            color: black;
            text-transform: uppercase;
            padding-bottom: 4px;
            border-bottom: 1px solid transparent;
            transition: border-color 0.2s ease-in-out;
        }

        &::after {
            content: '';
            position: absolute;
            display: inline-block;
            vertical-align: baseline;
            top: 5px;
            right: -22px;
            width: 14px;
            height: 14px;
            background-size: 16px 16px;
            background-position: right center;
            background-repeat: no-repeat;
            background-image: url('#{$basepath}svg/icon-arrow.svg');
            transition: right 0.2s ease-in-out;
        }

        &:hover {
            span {
                border-color: black;
            }

            &::after {
                right: -28px;
            }
        }
    }

    &__DropdownListTitle {
        @extend %small-labels;
        color: $colorBlack;
        text-transform: uppercase;
        margin-bottom: 8px;
    }

    &__DropdownInnerWrap {
        margin-bottom: 16px;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &__DropdownLink {
        @extend %navigation-sub-titles;
        color: $colorBlack70;
        text-decoration: none;
        padding-left: 8px;
        margin-bottom: 16px;
        position: relative;
        display: flex;
        align-items: center;

        &--Arrow {
            padding-left: 24px;

            &:before {
                content: '';
                position: absolute;
                top: 2px;
                left: 0;
                width: 16px;
                height: 16px;
                display: block;
                background-size: 16px 16px;
                background-position: left center;
                background-repeat: no-repeat;
                background-image: url('#{$basepath}svg/icon-circle-arrow-gray.svg');
            }
        }

        &--Region {
            padding-left: 24px;

            &:before {
                content: '';
                position: absolute;
                top: 2px;
                left: 0;
                width: 16px;
                height: 16px;
                display: block;
                background-size: 16px 16px;
                background-position: left center;
                background-repeat: no-repeat;
                background-image: url('#{$basepath}svg/icon-region.svg');
            }
        }

        &--City {
            padding-left: 24px;

            &:before {
                content: '';
                position: absolute;
                top: 2px;
                left: 0;
                width: 16px;
                height: 16px;
                display: block;
                background-size: 16px 16px;
                background-position: left center;
                background-repeat: no-repeat;
                background-image: url('#{$basepath}svg/icon-map-marker.svg');
            }
        }

        &:hover {
            color: $colorBlack;

            @media (hover: none) {
                color: $colorBlack70;
            }
        }
    }
}
