@import 'styles/includes.scss';

.Button {
    $root: &;
    border: none;

    padding: 0;
    @extend %button-titles;
    text-decoration: none;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    text-transform: uppercase;
    color: $colorBlack70;

    transition: background-color $duration $ease;

    &:hover {
        background-color: $colorBlack08;

        #{$root}__Icon {
            transform: translateX(12px);
        }
    }

    &:focus {
        outline: none;
        background-color: $colorBlack12;
    }

    &::after {
        content: '';
        display: none;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 14px;
        margin: auto;
        width: 24px;
        height: 24px;
        border: 2px solid $colorGrey800;
        border-radius: 50%;
    }

    &--Link {
        @extend %navigation-titles;
        display: inline-block;
    }

    &--Plain {
        min-width: 150px;
        color: rgba(0, 0, 0, 0.7);
        padding: 9px 8px 8px;
        background-color: $colorPaleYellow;

        &:hover {
            background-color: $colorPaleYellow08;
        }

        @include media(m) {
            min-width: 170px;
            padding: 16px;
        }
    }

    &--Article {
        position: relative;
        width: 100%;
        padding: 18px 16px;
        text-align: left;
        text-transform: none;
        background-color: white;
        box-shadow: 0 2px 8px 0 rgba(black, 0.16);
    }

    &--Article#{$root}--HasIcon {
        padding-right: 16px + 56px;

        &::after {
            display: inline-block;
        }

        @include media(m) {
            padding-right: 24px + 56px;
        }
    }

    &--Primary {
        min-width: 150px;
        padding: 9px 8px 8px;
        background-color: $colorYellow;

        &:hover {
            background-color: $colorYellow08;
        }

        &:focus {
            background-color: $colorYellow12;
        }

        @include media(m) {
            min-width: 170px;
            padding: 16px 24px;
        }
    }

    &--SecondaryBlue {
        min-width: 150px;
        padding: 9px 8px 8px;
        background-color: $colorDawnBlue;
        color: #fff;

        &:hover {
            background-color: $colorDawnBlue08;
        }

        &:focus {
            background-color: $colorDawnBlue12;
        }

        @include media(m) {
            min-width: 170px;
            padding: 16px 24px;
        }

        .Button__Icon {
            background-image: url('#{$basepath}svg/icon-arrow-white.svg');

            &--Share {
                background-image: url('#{$basepath}svg/icon-share-white.svg');
            }
        }
    }

    &--Transparent {
        min-width: 150px;
        padding: 12px 30px 12px 16px;
        color: #fff;
        border: 1px solid #fff;

        @include media(m) {
            min-width: 170px;
            padding: 24px 50px 24px 33px;
        }

        span {
            transform: translateX(14px);

            @include media(m) {
                transform: translateX(18px);
            }
        }

        &:hover,
        &:focus {
            span {
                @include media(m) {
                    transform: translateX(24px);
                }
            }
        }
    }

    &--PrimarySmall {
        padding: 13px 27px 11px 23px;
        background-color: $colorYellow;
        border-radius: 0;

        font-size: 1.4rem;

        &:hover {
            background-color: $colorYellow08;
        }

        &:focus {
            background-color: $colorYellow12;
        }
    }

    &--Secondary {
        min-width: 150px;
        padding: 9px 8px 8px;
        background-color: $colorGrey200;
        border-radius: 0;

        &:hover {
            background-color: $colorBlack10;
        }

        &:focus {
            background-color: $colorBlack14;
        }

        @include media(m) {
            min-width: 170px;
            padding: 17px 16px 16px;
        }
    }

    &--SecondarySmall {
        padding: 12px 36px 10px;
        background-color: $colorGrey200;
        border-radius: 0;

        &:hover {
            background-color: $colorBlack10;
        }

        &:focus {
            background-color: $colorBlack14;
        }
    }

    &--WithMapExpanderHeight {
        height: 56px;
    }

    &--IsShareButton {
        padding: 18px 36px;
        width: 100%;
    }

    &--InsideShareFeedbackSegment {
        height: 56px;
        padding: 18px 31px;
        height: 100%;
        font-size: 1.6rem;
        width: 100%;

        @include media(m) {
            width: 98%;
        }

        @include media(tabletL) {
            width: auto;
        }
    }

    &--CampaignCTA {
        position: relative;
        font-weight: 400;
        padding-right: 48px;
        min-width: unset;

        border: 1px solid $colorBlack70;
        background-color: transparent;
        color: $colorBlack70;

        @include media(m) {
            font-size: 18px;
            padding: 24px 48px 24px 32px;
        }

        &.Button--CampaignCTA[href*='document-proxy'] {
            &:hover,
            &:focus {
                span {
                    transform: translateX(14px) scale(1.4);

                    @include media(m) {
                        transform: translateX(18px) scale(1.4);
                    }
                }
            }
        }
    }

    &--WhiteOnBlue {
        border: 1px solid #fff;
        background-color: transparent;
        color: #fff;

        .Button__Icon {
            background-image: url('#{$basepath}svg/icon-arrow-white.svg');
        }

        &.Button--CampaignCTA[href*='document-proxy'] {
            .Button__Icon {
                background-image: url('#{$basepath}svg/icon-download-white.svg');
            }
        }
    }

    &--YellowOnWhite,
    &--YellowOnBlue {
        background-color: $colorPaleYellow;
        border: 1px solid $colorPaleYellow;
        color: $colorBlack70;
        font-weight: bold;

        &:hover {
            background-color: $colorPaleYellow08;
        }

        .Button__Icon {
            background-image: url('#{$basepath}svg/icon-arrow.svg');

            &--ArrowDownload {
                background-image: url('#{$basepath}svg/icon-download.svg');
            }
        }
    }

    &--BlueOnYellow {
        border: 1px solid $colorDawnBlue;
        color: $colorDawnBlue;

        .Button__Icon {
            background-image: url('#{$basepath}svg/icon-arrow-blue.svg');

            &--ArrowDownload {
                background-image: url('#{$basepath}svg/icon-download.svg');
            }
        }
    }

    &--BlackOnYellow,
    &--BlackOnWhite {
        border: 1px solid $colorBlack70;
        color: $colorBlack70;

        .Button__Icon {
            background-image: url('#{$basepath}svg/icon-arrow.svg');

            &--ArrowDownload {
                background-image: url('#{$basepath}svg/icon-download.svg');
            }
        }
    }

    &__Icon {
        display: inline-block;

        height: 16px;
        width: 14px;

        background-size: contain;
        background-repeat: no-repeat;
        background-position-y: 4px;
        transition: transform 0.2s;
        transform: translateX(8px);

        #{$root}--Article & {
            width: 56px;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            background-size: 16px;
            background-position: center center;
            background-color: $colorPaleYellow;
            transform: none !important;
        }

        &--Arrow {
            background-image: url('#{$basepath}svg/icon-arrow.svg');

            #{$root}--Article & {
                background-size: 14px;
            }
        }

        &--ArrowWhite {
            background-image: url('#{$basepath}svg/icon-arrow-white.svg');
        }

        &--DiagonalArrow {
            background-image: url('#{$basepath}svg/icon-diagonal-arrow.svg');
            background-position-y: 2px;
        }

        &--Share {
            background-image: url('#{$basepath}svg/icon-share.svg');
        }

        &--ShareWhite {
            background-image: url('#{$basepath}svg/icon-share-white.svg');
        }

        &--ShareBlue {
            background-image: url('#{$basepath}svg/icon-share-blue.svg');
        }

        &--Plus {
            background-image: url('#{$basepath}svg/icon-plus.svg');
            width: 12px;
        }

        &--Play {
            background-image: url('#{$basepath}svg/icon-play.svg');
            transform: translateX(4px);

            @include media(l) {
                transform: translateX(8px);
            }
        }

        &--Bookmark {
            background-image: url('#{$basepath}svg/icon-bookmark.svg');
        }

        &--Bookmarked {
            background-image: url('#{$basepath}svg/icon-bookmarked.svg');
        }

        &--RoundClose {
            background-image: url('#{$basepath}svg/icon-round-close.svg');
            background-size: 86%;
            background-repeat: no-repeat;
        }

        &--Link {
            background-image: url('#{$basepath}svg/icon-link.svg');
        }

        &--ArrowDownload {
            height: 19px;
            width: 16px;
            background-size: 100%;
            background-image: url('#{$basepath}svg/icon-download.svg');
        }
    }
}
