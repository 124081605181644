@import 'styles/includes.scss';

.BookmarkButton {
    &--InsideShareFeedbackSegment {
        width: 100%;
        margin-bottom: 16px;
        margin-right: 0;

        @include media(m) {
            width: 50%;
            margin-right: 16px;
            margin-bottom: 0;
        }

        @include media(tabletL) {
            margin-right: 32px;
            width: auto;
        }
    }
}
