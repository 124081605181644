@import 'styles/includes.scss';

.BasicLogo {
    padding-top: 32px;
    position: absolute;
    width: 100%;
    z-index: 20;

    @include media(l) {
        padding-top: 60px;
    }

    &__Wrapper {
        @extend %container;
        padding: 0 32px;
        width: 100%;
    }

    &__Logotype {
        background-image: url('#{$basepath}svg/logotype-visitsweden.svg');
        background-size: contain;
        background-repeat: no-repeat;
        width: 160px;
        height: 33px;
        margin-right: 32px;
        position: relative;
        opacity: 1;
        transition: opacity 0.15s ease;

        &--Fade {
            opacity: 0.45;
        }

        a {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        @include media(m) {
            width: 184px;
        }
    }
}
