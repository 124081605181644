@import 'styles/includes.scss';

.NewsletterSignup {
    background-color: $colorYellow;

    &__Wrapper {
        @extend %container;
        display: flex;
        flex-direction: column;
        padding-top: 24px;
        padding-bottom: 24px;

        border-bottom: 1px solid rgba(black, 0.15);

        @include media(l) {
            flex-direction: row;
        }
    }

    &__Text {
        margin-bottom: 8px;

        @include media(l) {
            width: 70%;
            align-self: center;
            margin-bottom: 0;
        }
        @extend %body-copy;
    }

    &__Input {
        @include media(l) {
            width: 35%;
            flex-shrink: 0;
        }
        position: relative;

        [type='email'] {
            @extend %navigation-sub-titles;
            width: 100%;
            height: 56px;
            border: none;
            padding: 0 16px;
            background-color: $colorGrey100;
        }

        [type='submit'] {
            @extend %button-titles-small;
            background-color: #fff;
            position: absolute;
            right: 8px;
            top: 8px;
            border: 2px solid $colorBlack70;
            height: 40px;
            padding: 0 20px;
        }
    }
}
